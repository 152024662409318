import { API, get } from 'api/base';

const mock = {
  email: 'sherinlin1738@gmail.com',
  name: 'Sherin Lin',
  first_name: 'Sherin',
  last_name: 'Lin',
  username: 'sherin-test',
  pool_hall: 'Kou Hoaloha',
  player_level: 'SB',
  rating: 0,
  matches_played: 0,
};

/**
 * [GET /dev/accounts]
 *
 * { email: STRING }
 *
 * Get player information
 *
 */

const getPlayerData = (email) => {
  const endpointDomain = process.env.REACT_APP_API_DOMAIN;

  // await mockAwait(500);
  // return undefined;

  return get(
    API(endpointDomain, `dev/accounts`, {
      email,
    })
  );
};

export default getPlayerData;
