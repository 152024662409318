import clsx from 'clsx';

const Basic = ({ onClick, className, children }) => {
  return (
    <button className={clsx('px-4 py-2 rounded', className)} onClick={onClick}>
      {children}
    </button>
  );
};

const Buttons = { Basic };

export default Buttons;
