import { jwtDecode } from 'jwt-decode';

export const checkGoogleSingIn = () => {
  const localUser = localStorage.getItem('user');

  if (!localUser) return false;

  const userData = JSON.parse(localUser);
  const now = new Date().getTime();

  if (now > userData.expire || !userData.credential) return false;
  const decoded = jwtDecode(userData.credential);

  return decoded;
};
