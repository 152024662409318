const UI = (state, action) => {
  switch (action.type) {
    case 'NOTIFY_ON': {
      return {
        ...state,
        notify: {
          isOpen: true,
          type: action.payload.type,
          title: action.payload.title,
          message: action.payload.message,
        },
      };
    }

    case 'NOTIFY_OFF': {
      return { ...state, notify: { isOpen: false } };
    }

    default:
      throw new Error();
  }
};

export default UI;
