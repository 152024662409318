import { API, get } from 'api/base';

const toPoolHall = (data) => {
  if (!data) return null;

  return data.pool_hall;
};

/**
 * [GET /dev/pool_halls]
 *
 * {  }
 *
 * Get pool hall list
 *
 */

export default function getPoolHall() {
  const endpointDomain = process.env.REACT_APP_API_DOMAIN;

  // await mockAwait(500);
  // return [
  //   {
  //     pool_hall: 'Jack Sangenjaya',
  //   },
  //   {
  //     pool_hall: 'Kou Hoaloha',
  //   },
  // ].map(toPoolHall);
  return get(API(endpointDomain, `dev/pool_halls`)).then((result) => result.map(toPoolHall));
}
