import { GoogleOAuthProvider } from '@react-oauth/google';
import { DataContextProvider } from 'store/data';
import { UIContextProvider } from 'store/ui';
import { UserContextProvider } from './store/user';

const withAppProviders = (Component) => (props) => {
  function WrapperComponent() {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
        <UIContextProvider>
          <DataContextProvider>
            <UserContextProvider>
              <Component {...props} />
            </UserContextProvider>
          </DataContextProvider>
        </UIContextProvider>
      </GoogleOAuthProvider>
    );
  }

  return WrapperComponent;
};

export default withAppProviders;
