import SystemNotify from 'components/element/Modal/SystemNotify';
import { createContext, useContext, useMemo, useReducer } from 'react';

import UIReducer from './reducer';

const initialState = { notify: { isOpen: false } };

export const UserContext = createContext();

export function UIContextProvider(props) {
  const [state, dispatch] = useReducer(UIReducer, initialState);

  const { notify } = state;

  const value = useMemo(
    () => ({
      state,
      loader: {
        start: () => dispatch({ type: 'LOADER_START' }),
        stop: () => dispatch({ type: 'LOADER_STOP' }),
      },
      notify: {
        on: ({ type, title, message }) =>
          dispatch({ type: 'NOTIFY_ON', payload: { type, title, message } }),
        off: () => dispatch({ type: 'NOTIFY_OFF' }),
      },
    }),
    [state]
  );

  return (
    <UserContext.Provider value={value}>
      {props.children}

      <SystemNotify notify={notify} onClose={() => dispatch({ type: 'NOTIFY_OFF' })} />
    </UserContext.Provider>
  );
}

export default function useUI() {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error('wrap parent component');
  }
  return context;
}
