import getPlayerData from 'api/player/getPlayerData';
import { isError } from 'functions/tool';
import { checkGoogleSingIn } from 'functions/user';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import UserReducer from './reducer';

const initialState = { user: undefined };

export const UserContext = createContext();

export function UserContextProvider(props) {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const setPlayerData = useCallback((googleData) => {
    getPlayerData(googleData.email)
      .then((data) => {
        if (isError(data)) throw data;

        dispatch({ type: 'SET_USER', payload: { status: 'user', ...googleData, ...data } });
      })
      .catch((error) => {
        dispatch({ type: 'SET_USER', payload: { status: 'guest', ...googleData } });
      });
  }, []);

  useEffect(() => {
    if (state.user) return;

    const googleUserInfo = checkGoogleSingIn();
    if (!googleUserInfo) {
      dispatch({ type: 'SET_USER', payload: { status: 'log-out' } });
      return;
    }

    setPlayerData(googleUserInfo);
  }, [setPlayerData, state?.user]);

  const setUser = (user) => {
    dispatch({ type: 'SET_USER', payload: user });
  };

  const setLogout = () => {
    localStorage.removeItem('user');
    dispatch({ type: 'SET_USER', payload: 'log-out' });
    window.open('/', '_self');
  };

  const value = useMemo(
    () => ({
      state,
      setUser,
      setLogout,
      setPlayerData,
    }),
    [setPlayerData, state]
  );

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
}

export default function useUser() {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error('wrap parent component');
  }
  return context;
}
