export const isError = (result) => {
  // if (isJWTExpire()) return true;

  return result?.success === false;
};

export const errorNotify = (notify, error) =>
  notify.on({
    type: 'error',
    title: error.message,
    message: `Unable to retrieve player information. (${error.status})`,
  });
