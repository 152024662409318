import { lazy } from 'react';
import Main from './page/Main';

const SignupPage = lazy(() => import('./page/SignupPage'));
const SignInPage = lazy(() => import('./page/SignInPage'));
const BoardPage = lazy(() => import('./page/BoardPage'));

const routes = [
  {
    path: `/`,
    menu: false,
    element: <Main />,
  },
  {
    path: `/sign-in`,
    menu: false,
    element: <SignInPage />,
  },
  {
    path: `/sign-up`,
    menu: false,
    element: <SignupPage />,
  },
  {
    path: `/board`,
    menu: true,
    element: <BoardPage />,
  },
];

export default routes;
