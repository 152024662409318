import MobileFrame from 'components/layout/MobileFrame';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from 'store/user';

export default function Main({ isMenu, children }) {
  const { state } = useUser();

  const { user } = state;

  const navigate = useNavigate();

  useEffect(() => {
    if (user === undefined || !user.status) navigate('/sign-in');

    if (user.status === 'log-out') navigate('/sign-in');

    if (user.status === 'guest') navigate('/sign-up');

    if (user.status === 'user') navigate('/board');
  }, [user, navigate]);

  return (
    <MobileFrame>
      <>
        {isMenu && (
          <div className="h-16 bg-white shadow grid content-center px-5">
            <h1 className="text-2xl font-bold">PocketPro</h1>
          </div>
        )}
        <div className="h-full w-full">{children}</div>
      </>
    </MobileFrame>
  );
}
