import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import withAppProviders from './withAppProviders';
import routes from 'routes';
import useUser from 'store/user';
import Main from 'routes/page/Main';
import './App.css';
import MobileFrame from 'components/layout/MobileFrame';
import LoadingSVG from 'components/element/LoadingSVG';

function App() {
  const { state } = useUser();

  const { user } = state;

  if (user === undefined)
    return (
      <MobileFrame>
        <div className="w-full h-full flex items-center justify-center">
          <LoadingSVG />
        </div>
      </MobileFrame>
    );

  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<Main isMenu={route.menu}>{route.element}</Main>}
          ></Route>
        ))}
      </Routes>
    </Router>
  );
}

export default withAppProviders(App)();
